#reader {
    width: 100%;
    aspect-ratio: 4/1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#reader video,
#reader canvas {
    width: 100% !important;
}

.cardTable * {
    font-size: 20px;
}

.cardTable td {
    padding: 0 10px;
}

input.pin {
    margin: calc(var(--spacing) / 2) 0 !important;
    width: calc(6ch + var(--form-element-spacing-horizontal) * 2) !important;
}

.newCard {
  animation: newCardFade 4s ease-out;
}

@keyframes newCardFade {
  from {
    background-color: green;
  }
  to {
    background-color: transparent;
  }
}
